<template>

  <div class="row" ref="categoryForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <ValidationProvider
                    vid="name"
                    rules="required"
                    name="The name"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Name"
                              name="name"
                              fou
                              @keyup="generateMetaTitleURL"
                              v-model="formData.name">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    vid="parent_id"
                    rules=""
                    name="The Parent"
                    v-slot="{ passed, failed,errors }">
                    <fg-select
                      name="parent_id"
                      size="large"
                      filterable
                      placeholder="Select Parent"
                      :input-classes="'select-default'"
                      :label="'Parent'"
                      :error="failed ? errors[0]: null"
                      :list="categories"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.parent_id">
                    </fg-select>
                  </ValidationProvider>
                  <ValidationProvider
                    vid="url"
                    rules="required"
                    name="The url"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Url"
                              name="url"
                              fou
                              v-model="formData.url">
                    </fg-input>
                  </ValidationProvider>

                </div>
                <div class="col-xs-12 col-sm-12 col-md-6">

                  <ValidationProvider
                    vid="meta_title"
                    rules="required"
                    name="The Meta Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Meta Title"
                              name="meta_title"
                              fou
                              v-model="formData.meta_title">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    vid="meta_description"
                    rules=""
                    name="The Meta Description"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Meta Description"
                              name="meta_description"
                              fou
                              v-model="formData.meta_description">
                    </fg-input>
                  </ValidationProvider>
                  <div class="form-group mt-5">
                    <label>Is Active</label>&nbsp;
                    <l-switch v-model="formData.is_active">
                      <i class="fa fa-check" slot="on"></i>
                      <i class="fa fa-times" slot="off"></i>
                    </l-switch>
                  </div>
                </div>
              </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/categories/list')" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import {FormGroupSelect, ImageUploader, Switch as LSwitch} from "@/components";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    FgSelect,
    LSwitch,
    FormGroupSelect,
    ImageUploader
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        brief:"",
        parent_id:null,
        url: "",
        meta_title:"",
        meta_description:"",
        is_active:false,
        sort_order:null,
      },
      categories:[],

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.categoryForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Category";
      this.getCategory();
    } else {
      this.editMode = false;
      this.formTitle = "Add Category";
    }
    this.getBuildThings();
  },


  methods: {

    async getCategory() {
      try {
        let response = await this.axios.get("categories/get/" + this.id);
        this.formData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Category Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
        this.loader.hide();
      }
    },

    async getBuildThings() {
      try {
        let response = await this.axios.get("categories/builder");
        this.categories = response.data.items.categories;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Category Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
        this.loader.hide();
      }
    },

    async submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("categories/update/" + this.id, this.formData);
        successMessage = "Category Updated Successfully";
      } else {
        request = this.axios.post("categories/create", this.formData);
        successMessage = "Category Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/categories/list");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },

    generateMetaTitleURL() {

      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
      // meta title
      this.formData.meta_title = this.formData.name;
    },

  }
}
</script>

<style>
</style>
